import React, { Fragment, useEffect } from 'react';
import {
    Box,
    Typography,
    Divider,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    Grid,
    CircularProgress,
    Menu,
    MenuItem,
    TextField,
    Snackbar,
    ListItem,
    Collapse,
    List
} from '@material-ui/core';
import { getUser } from '../../utils';
import * as api from '../../services/apiService';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import history from '../../utils/history';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import caseDetailsStyle from './Styles';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinesEllipsis from 'react-lines-ellipsis';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import UpgradeDialog from '../../components/Dialogs/UpgradeDialog';
import {
    caseProcedureType,
    hospitalDataType,
    mediaType,
    procedureDataType,
    rolesDataType
} from '../../types';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import { object } from 'yup';
import RegistryDialog from '../../components/Dialogs/RegistryDialog';

type caseParams = {
    caseId: string;
    registryId?: string;
    doctorId?: string;
};

const CaseProcedureDetails = (props: any) => {
    const classes = caseDetailsStyle();
    const { caseId, registryId, doctorId } = useParams<caseParams>();
    const normalData = {
        patient_first_name: '',
        patient_last_name: '',
        date_of_birth: '',
        mrn: null,
        age: null,
        primary_surgeon: null,
        race: '',
        insurance: '',
        gender: '',
        date: '',
        type: null,
        procedure_name: '',
        approach: '',
        indication: '',
        implant: '',
        implant_type: '',
        location: '',
        osteotomy: '',
        _fusion: '',
        fusion_levels: '',
        decompression_levels: '',
        interbody_fusion: '',
        extension_to_pelvis: '',
        morphogenic_protein: '',
        instrumentation: '',
        target: '',
        nerve: '',
        _procedure: '',
        tici_grade: '',
        access: '',
        vascular_closure_device: '',
        duration_of_radiation: '',
        duration_of_procedure: '',
        date_of_procedure: '',
        role: null,
        notes: [],
        length_in_minutes: null,
        vendors: null,
        _vendors: '',
        complications: null,
        findings: null,
        outcome: null,
        length_of_hospitalizations_in_days: null,
        follow_up_number: 0,
        follow_up_period: '',
        additional_info: ''
    };

    const registryData = {
        patient_first_name: '',
        patient_last_name: '',
        date_of_birth: '',
        mrn: null,
        images: null,
        newNote: null,
        age: null,
        primary_surgeon: null,
        race: '',
        institute: null,
        diagnosisCodes: null,
        cptCodes: null,
        insurance: '',
        gender: '',
        date: '',
        type: null,
        procedure_name: '',
        approach: '',
        indication: '',
        implant: '',
        implant_type: '',
        location: '',
        osteotomy: '',
        _fusion: '',
        fusion_levels: '',
        decompression_levels: '',
        interbody_fusion: '',
        extension_to_pelvis: '',
        morphogenic_protein: '',
        instrumentation: '',
        target: '',
        nerve: '',
        _procedure: '',
        tici_grade: '',
        access: '',
        vascular_closure_device: '',
        duration_of_radiation: '',
        duration_of_procedure: '',
        date_of_procedure: '',
        role: null,
        notes: [],
        length_in_minutes: null,
        vendors: null,
        _vendors: '',
        complications: null,
        findings: null,
        outcome: null,
        length_of_hospitalizations_in_days: null,
        follow_up_number: 0,
        follow_up_period: '',
        additional_info: ''
    };
    const [caseIndicator, setCaseIndicator] = React.useState<boolean>(false);
    const [openExportCase, setOpenExportCase] = React.useState<boolean>(false);
    const [
        showImportCaseMsgReg,
        setShowImportCaseMsgReg
    ] = React.useState<boolean>(false);
    const [sumOfRvu, setSumOfRvu] = React.useState<number>(0);
    const [importCaseMsgReg, setImportCaseMsgReg] = React.useState<string[]>([]);
    const [
        openExportCaseDialog,
        setOpenExportCaseDialog
    ] = React.useState<boolean>(false);
    const [procedureData, setProcedureData] = React.useState<caseProcedureType>(
        props.procedure
    );
    const [hospitalData, setHospitalData] = React.useState<hospitalDataType>({
        name: ''
    });
    const [roleList, setRoleList] = React.useState<rolesDataType[]>([]);
    const [procedureCPTData, setProcedureCPTData] = React.useState<any[]>([]);
    const [registryFields, setRegistryFields] = React.useState<any[]>([]);
    const [procedureICDData, setprocedureICDData] = React.useState<any[]>([]);
    const [media, setMedia] = React.useState<mediaType[]>([
        { deleted_flag: false, image_name: '', id: '' }
    ]);
    const [previewImage, setPreviewImage] = React.useState<string>('');
    const [openImage, setOpenImage] = React.useState<boolean>(false);

    const [open, setOpen] = React.useState<boolean>(false);
    const [openCOllapse, setOpenCOllapse] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string[]>([]);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [isOpenConfirm, setIsOpenConfirm] = React.useState<boolean>(false);
    const [registryName, setRegistryName] = React.useState<string>();
    const [customizedField, setCustomizedField] = React.useState<string[]>([]);
    const [customizedData, setCustomizedData] = React.useState<any[]>([]);
    const [cptTitle, setCptTitle] = React.useState<any[]>([]);
    const [normalFields, setNormalFields] = React.useState<any[]>([]);
    const [tokenError, setTokenError] = React.useState<boolean>(false);
    const [userToken, setUserToken] = React.useState<string>('');
    const [isOpen, setisOpen] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = React.useState<any>();
    const [messageError, setMessageError] = React.useState<string>('');
    const [newPlan, setNewPlan] = React.useState<boolean>(false);

    const user = getUser();
    const location = useLocation().pathname;

    const isNeurosurgery = () => {
        return user.user_profile[0].speciality?.name === 'Neurological Surgery'
    }

    const isInterventional = () => {
        return user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology';
    }

    const isRadiology = () => {
        return user.user_profile[0].speciality?.name == 'Interventional Radiology';
    }

    const isEmptyIndication = () => {
        procedureData.indication = procedureData.indication === null ? '' : procedureData.indication;
        return procedureData.indication === '' || procedureData.indication?.split(', ').includes('');
    }

    const isEmptyProcedure = () => {
        return procedureData.type === '';
    }

    const isOtherIndication = () => {
        procedureData.indication = procedureData.indication === null ? '' : procedureData.indication;
        return procedureData.indication === 'Other' || procedureData.indication?.split(', ').includes('Other');
    }

    const isOtherProcedure = () => {
        return procedureData.type === 'Other';
    }

    const isTumor = () => {
        return procedureData.indication === 'Tumor' || procedureData.indication?.split(', ').includes('Tumor');
    }

    const isPain = () => {
        return procedureData.indication === 'Pain' || procedureData.indication?.split(', ').includes('Pain');
    }

    const isCarotid = () => {
        return procedureData.indication === 'Carotid stenosis' || procedureData.indication?.split(', ').includes('Carotid stenosis');
    }
    const isAVF = () => {
        return procedureData.indication === 'AVF' || procedureData.indication?.split(', ').includes('AVF');
    }

    const isAVM = () => {
        return procedureData.indication === 'AVM' || procedureData.indication?.split(', ').includes('AVM');
    }

    const isAbscess = () => {
        return procedureData.indication === 'Abscess' || procedureData.indication?.split(', ').includes('Abscess');
    }

    const isAneurysm = () => {
        return procedureData.indication === 'Aneurysm' || procedureData.indication?.split(', ').includes('Aneurysm');
    }

    const isHematoma = () => {
        return procedureData.indication === 'Hematoma' || procedureData.indication?.split(', ').includes('Hematoma');
    }

    const isFracture = () => {
        return procedureData.indication === 'Fracture' || procedureData.indication?.split(', ').includes('Fracture');
    }

    const isHydrocephalus = () => {
        return procedureData.indication === 'Hydrocephalus' || procedureData.indication?.split(', ').includes('Hydrocephalus');
    }

    const isBleeding = () => {
        return procedureData.indication === 'Bleeding' || procedureData.indication?.split(', ').includes('Bleeding');
    }

    const isDissection = () => {
        return procedureData.indication === 'Dissection' || procedureData.indication?.split(', ').includes('Dissection');
    }

    const isVasospasm = () => {
        return procedureData.indication === 'Vasospasm' || procedureData.indication?.split(', ').includes('Vasospasm');
    }

    const isStroke = () => {
        return procedureData.indication === 'Stroke' || procedureData.indication?.split(', ').includes('Stroke');
    }

    const isCranial = () => {
        return procedureData.type === 'Cranial';
    }

    const isSpine = () => {
        return procedureData.type === 'Spine';
    }

    const isFunctional = () => {
        return procedureData.type === 'Functional';
    }

    const isPeripheral = () => {
        return procedureData.type === 'Peripheral nerves';
    }

    const isInter = () => {
        return procedureData.type === 'Neurointerventional';
    }

    const isBedside = () => {
        return procedureData.type === 'Bedside';
    }

    const isThrombectomy = () => {
        return procedureData._procedure === 'Thrombectomy' || procedureData._procedure?.split(', ').includes('Thrombectomy');
    }

    const showLocation = () => {
        return (((isNeurosurgery() && isInter()) && (isCarotid() || isPain() || isOtherIndication()) ||
            (isInterventional() && (isCarotid() || isPain() || isOtherIndication()))) ||
            ((isNeurosurgery() && !(isFunctional() || isPeripheral())) &&
                !(isEmptyIndication() || isOtherIndication()) ||
                isNeurosurgery() && isSpine()) ||
            (isInterventional() && !isEmptyIndication()) || isRadiology());
    }

    return (

        <Box mb={3}>
            <Paper className={classes.paper}>
                <Grid item={true} container spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Typography
                            className={[classes.header, classes.Padding].join(' ')}
                            variant="h4"
                        >
                            Procedures {props.index + 2}
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Divider className={classes.divider} />
                    </Grid>

                    {isNeurosurgery() || isInterventional() || isRadiology() ?
                        (<Grid item={true} xs={12} sm={6} md={6} lg={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Date of Procedure
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.date_of_procedure ? procedureData.date_of_procedure : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('procedure_name') !== -1) &&
                        isNeurosurgery() || isInterventional() || isRadiology() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Procedure name
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.procedure_name ? procedureData.procedure_name : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                        <Typography
                            className={[classes.label, classes.Padding].join(' ')}
                            variant="h4"
                        >
                            Procedure Type
                        </Typography>
                        <Typography
                            variant="h3"
                            className={[classes.Padding, classes.fontSize].join(' ')}
                        >
                            {procedureData.type ? procedureData.type : 'N/A'}
                        </Typography>
                        <Divider />
                    </Grid>

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Cranial' ||
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Spine' ||
                        registryFields.indexOf('approach') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Cranial' ||
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Spine' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Approach
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.approach ? procedureData.approach : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('minimally_invasive') !== -1) &&
                        isNeurosurgery() && isSpine() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Minimally invasive
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.minimally_invasive ? procedureData.minimally_invasive : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('navigation') !== -1) &&
                        isNeurosurgery() && isSpine() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Navigation
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.navigation ? procedureData.navigation : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('robotic') !== -1) &&
                        isNeurosurgery() && isSpine() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Robotic
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.robotic ? procedureData.robotic : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('indication') !== -1) &&
                        isNeurosurgery() && isBedside() ||
                        isNeurosurgery() && procedureData.type !== '' && procedureData.type !== 'Other' ||
                        isInterventional() || isRadiology() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Indication
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.indication ? procedureData.indication : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('side') !== -1) &&
                        isNeurosurgery() || isInterventional() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Side
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.side ? procedureData.side : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('location') !== -1) &&
                        showLocation() ?
                        (<Grid item={true} xs={12} sm={6} md={6} lg={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Location
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.location ? procedureData.location : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('_procedure') !== -1) &&
                        ((isNeurosurgery() && isBedside() ||
                            isNeurosurgery() && isInter()) &&
                            !isInterventional()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Procedure
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData._procedure ? procedureData._procedure : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') ||
                        registryFields.indexOf('tici_grade') !== -1) &&
                        (isNeurosurgery() && isInter() && isThrombectomy() ||
                            isInterventional() && isThrombectomy()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                TICI grade
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.tici_grade ? procedureData.tici_grade : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type === 'Spine' ||
                        registryFields.indexOf('osteotomy') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type === 'Spine' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Osteotomy
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.osteotomy ? procedureData.osteotomy : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ||
                        registryFields.indexOf('_fusion') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Fusion
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData._fusion ? procedureData._fusion : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}


                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ||
                        registryFields.indexOf('interbody_fusion') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Interbody fusion
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.interbody_fusion ? procedureData.interbody_fusion : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ||
                        registryFields.indexOf('extension_to_pelvis') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Extension to pelvis
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.extension_to_pelvis ? procedureData.extension_to_pelvis : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') && isNeurosurgery() && isSpine() ||
                        registryFields.indexOf('fusion_levels') !== -1 && isNeurosurgery() && isSpine() ? (
                        <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Fusion levels
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.fusion_levels ? procedureData.fusion_levels : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') && isNeurosurgery() && isSpine() ||
                        registryFields.indexOf('decompression_levels') !== -1 && isNeurosurgery() && isSpine() ? (
                        <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Decompression levels
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.decompression_levels ? procedureData.decompression_levels : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ||
                        registryFields.indexOf('morphogenic_protein') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Spine' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Morphogenic protein
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.morphogenic_protein ? procedureData.morphogenic_protein : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('implant') !== -1) &&
                        ((isNeurosurgery() && !isSpine() && !isEmptyIndication() && !isOtherProcedure()) ||
                            isInterventional() || isRadiology()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Implant
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.implant ? procedureData.implant : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('implant_type') !== -1) &&
                        ((isNeurosurgery() && !isSpine() && !isEmptyIndication() && !isOtherProcedure()) ||
                            isInterventional() || isRadiology()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Implant type
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.implant_type ? procedureData.implant_type : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type !== '' && procedureData.type !== 'Other' ||
                        user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                        !user.user_profile[0].speciality || user.user_profile[0].speciality?.name ||
                        registryFields.indexOf('_vendors') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type !== '' && procedureData.type !== 'Other' ||
                        user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                        !user.user_profile[0].speciality || user.user_profile[0].speciality?.name ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Vendors
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData._vendors ? procedureData._vendors : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type !== '' && procedureData.type !== 'Other' ||
                        user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                        !user.user_profile[0].speciality || user.user_profile[0].speciality?.name ||
                        registryFields.indexOf('vendors') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type !== '' && procedureData.type !== 'Other' ||
                        user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                        !user.user_profile[0].speciality || user.user_profile[0].speciality?.name) && procedureData._vendors?.includes('Other') ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Other vendors
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.vendors ? procedureData.vendors : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}


                    {(!location.includes('/registrycase/') || registryFields.indexOf('access') !== -1) &&
                        (isInterventional() || isRadiology()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Access
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.access
                                    ? procedureData.access
                                    : 'N/A'}
                            </Typography>
                            {registryId ? <Divider /> : null}
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('vascular_closure_device') !== -1) &&
                        (isInterventional() || isRadiology()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Vascular closure device (if used)
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.vascular_closure_device
                                    ? procedureData.vascular_closure_device
                                    : 'N/A'}
                            </Typography>
                            {registryId ? <Divider /> : null}
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('duration_of_radiation') !== -1) &&
                        (isNeurosurgery() && !isEmptyProcedure() && isInter() ||
                            isInterventional() || isRadiology()) ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Duration of radiation
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.duration_of_radiation ? procedureData.duration_of_radiation : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' ||
                        !location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                        registryFields.indexOf('duration_of_procedure') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' ||
                        registryFields.indexOf('duration_of_procedure') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Duration of procedure
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.duration_of_procedure ? procedureData.duration_of_procedure : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Functional' ||
                        registryFields.indexOf('target') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Functional' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Target
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.target ? procedureData.target : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!location.includes('/registrycase/') &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Peripheral nerves' ||
                        registryFields.indexOf('nerve') !== -1 &&
                        user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                        procedureData.type === 'Peripheral nerves' ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Nerve
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.nerve ? procedureData.nerve : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {!isNeurosurgery() && !isInterventional() && !isRadiology() ?
                        (<Grid item={true} xs={12} sm={6} md={6} lg={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Date of Procedure
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.date ? procedureData.date : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('findings') !== -1) &&
                        !isRadiology() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Findings
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.findings ? procedureData.findings : 'N/A'}
                            </Typography>
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('complications') !== -1) &&
                        !isRadiology() ? (
                        <Grid item={true} xs={12} sm={6} md={6}>
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Complications
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.complications
                                    ? procedureData.complications
                                    : 'N/A'}
                            </Typography>
                            {registryId ? <Divider /> : null}
                            <Divider />
                        </Grid>
                    ) : null}

                    {(!location.includes('/registrycase/') || registryFields.indexOf('outcome') !== -1) &&
                        !isRadiology() ? (
                        <Grid
                            item={true}
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.procedureDataFieldLast}
                        >
                            <Typography
                                className={[classes.label, classes.Padding].join(' ')}
                                variant="h4"
                            >
                                Outcome
                            </Typography>
                            <Typography
                                variant="h3"
                                className={[classes.Padding, classes.fontSize].join(' ')}
                            >
                                {procedureData.outcome ? procedureData.outcome : 'N/A'}
                            </Typography>
                            {registryId ? <Divider /> : null}
                            <Divider />
                        </Grid>
                    ) : null}
                </Grid>
            </Paper>
        </Box>
    )
};

export default CaseProcedureDetails;
