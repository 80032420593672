import React, { useEffect, useState } from 'react';
import { Box, Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import Collections from '@material-ui/icons/Collections';
import caseMediaViewerStyle from './Styles';

const CaseMediaViewer = ({ media, style }) => {
  const classes = caseMediaViewerStyle();
  const [imageIndex, setImageIndex] = useState(0);
  const [openImage, setOpenImage] = useState(false);

  const handleImageClose = (event) => {
    event.stopPropagation();
    setOpenImage(false);
  };

  const handleNext = (event) => {
    event.stopPropagation();
    setImageIndex((prevIndex) => (prevIndex === media.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = (event) => {
    event.stopPropagation();
    setImageIndex((prevIndex) => (prevIndex === 0 ? media.length - 1 : prevIndex - 1));
  };

  // Preload images
  useEffect(() => {
    if (media && media.length > 0) {
      const preloadImages = (images) => {
        images.forEach(image => {
          const img = new Image();
          img.src = image.urls.href_original;
        });
      };
      preloadImages(media);
    }
  }, [media]);

  return (
    <>
      <Collections
        onClick={(event) => {
          event.stopPropagation();
          setOpenImage(true);
        }}
        color="secondary"
        fontSize="small"
        style={style}
      />
      <Dialog open={openImage} onClick={(event) => event.stopPropagation()}>
        <Box className={classes.prevwiedImage}>
          <Box className={classes.dialogTitle}>
            <Box></Box>
            <ClearIcon
              onClick={handleImageClose}
              className={classes.cursor}
            />
          </Box>
          <img
            alt="caseImg"
            className={classes.previewImage}
            src={media[imageIndex]?.urls?.href_original}
          />
        </Box>
        {media?.length > 1 && <>
          <ArrowRight
            onClick={handleNext}
            className={classes.nextButton}
            fontSize="small" />
          <ArrowLeft
            onClick={handlePrev}
            className={classes.prevButton}
            fontSize="small" />
        </>}
      </Dialog>
    </>
  );
};

export default CaseMediaViewer;
