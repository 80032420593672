import React from 'react'
import { Box, Typography } from '@material-ui/core'
import calendarStyles from './Styles'
import { format } from '../../utils/date';
import CaseMediaViewer from '../CaseMedia/CaseMedia';
import { getAge } from '../../utils';

export default function CalendarCaseItem({ item }) {
  const classes = calendarStyles();

  return (
    <Box className={classes.caseCardParent}>
      <Box className={classes.caseCard}>
        <Box className={classes.avatar}>
          <p>{format(item.date_of_procedure, 'MMM DD')}</p>
        </Box>
        <Box style={{ paddingRight: 10, paddingLeft: 10 }}>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              variant="h2"
              noWrap
            >
              {item &&
                item.patient_last_name +
                ', ' +
                item.patient_first_name}

              {item?.media && item.media.length > 0 && <CaseMediaViewer media={item.media} style={{ zIndex: 1000, position: 'absolute', top: 15, right: 22 }} />}
            </Typography>
            <Typography
              style={{ flex: 1, marginLeft: 10 }}
              variant="caption"
              noWrap
            >
              {item.date_of_birth ? getAge(item.date_of_birth) : item.age && item.age == 1 ? item.age + 'y' : item.age && item.age > 1 ? item.age + 'y' : null}
              {' ' + item?.gender?.charAt(0).toUpperCase() + item?.gender?.substring(1).toLowerCase()}
            </Typography>
          </Box>
          <Typography className={classes.institution}>{item?.hospital?.name}</Typography>
          <Typography className={classes.procedureName}>{item?.procedure_name ? item.procedure_name : item.type}</Typography>
        </Box>
      </Box>
    </Box>
  )
}