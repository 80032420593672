import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './customCalendar.css';
import { Dialog, DialogActions, DialogContent, Button, Typography, Box } from '@material-ui/core';
import * as api from '../../services/apiService';
import { getUser } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import CalendarCaseItem from './CalendarCaseItem';
import calendarStyles from './Styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DefaultCustomEvent = ({ event }) => {
  const { procedure } = event;
  return (
    <div>
      <strong>{procedure.procedure_name || procedure.type}</strong>
    </div>
  );
};

const DayViewCustomEvent = ({ event }) => {
  const { procedure } = event;
  return (
    <CalendarCaseItem item={procedure} />
  );
};

const CalendarWithNumbers = ({ setCases, proceduresCount, isOpen = false, setIsOpen, selectedDate = new Date() }) => {
  const [events, setEvents] = useState<any[]>([]);
  const [currentView, setCurrentView] = useState('month');
  const [calendarDate, setCalendarDate] = useState(selectedDate);
  const history = useHistory();
  const classes = calendarStyles();

  const formats = {
    weekdayFormat: (date, culture, localizer) => format(date, 'EEE', { locale: enUS }),
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: currentView === 'day' ? 'white' : '#073B70',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      fontWeight: 'regular',
      border: '0px',
      display: 'block',
      fontSize: '14px',
      marginBottom: currentView === 'day' ? 0 : '2px',
      padding: currentView === 'day' ? 0 : 5,
      width: currentView === 'day' ? '100%' : 'auto',
    };

    return {
      style: style,
    };
  };

  useEffect(() => {
    async function fetchEvents() {
      const { data } = await api.searchCases(getUser().id, 0, proceduresCount, {
        filter: 'ALL',
        search_by: 'INFO',
        value: ''
      });
      const allProcedures: any[] = [];
      for (let p of data.procedures) {
        allProcedures.push(p);
        for (let _p of p.case_procedures) {
          _p.id = p.id;
          _p.patient_first_name = p.patient_first_name;
          _p.patient_last_name = p.patient_last_name;
          _p.hospital = p.hospital;
          _p.gender = p.gender;
          _p.media = p.media;
          allProcedures.push(_p);
        }
      }
      const mappedEvents = allProcedures.map((proc: any) => ({
        title: `${!proc.procedure_name || proc.procedure_name == 'undefined' ? proc.type : proc.procedure_name}`,
        id: proc.id,
        start: parse(proc.date_of_procedure, 'yyyy-MM-dd', new Date()),
        end: parse(proc.date_of_procedure, 'yyyy-MM-dd', new Date()),
        allDay: true,
        procedure: proc
      }));
      setEvents(mappedEvents);
    }

    fetchEvents();
  }, [proceduresCount]);

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const navigateToAddCase = () => {
    history.push('/new-case', { date: new Date(calendarDate) });
  }

  return (
    <>
      <Button
        onClick={handleButtonClick}
        className={classes.headerButton}
        variant="contained"
        color="secondary"
      >
        <DateRangeIcon className={classes.icon}></DateRangeIcon >
        Calender View
      </Button>
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth="lg">
        <DialogContent>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 650 }}
            formats={formats}
            date={calendarDate}
            onNavigate={setCalendarDate}
            onSelectEvent={event => history.push(`case/${event.id}`)}
            eventPropGetter={eventStyleGetter}
            components={{
              event: (props) => {
                return currentView === 'day' ? (
                  <DayViewCustomEvent {...props} />
                ) : (
                  <DefaultCustomEvent {...props} />
                );
              },
            }}
            onView={handleViewChange} // Track view changes
            view={currentView} // Set the current view
          />
        </DialogContent>
        <DialogActions style={{
          alignItems: 'center',
          justifyContent: currentView === 'day' ? 'space-between' : 'flex-end'
        }}>
          {currentView == 'day' && <Button
            onClick={navigateToAddCase}
            className={classes.headerButton}
            variant="contained"
            color="secondary"
          >
            <AddIcon className={classes.icon}></AddIcon>
            Add Case
          </Button>}
          <Button
            onClick={handleClose}
            className={classes.headerButton}
            variant="contained"
            style={{ color: 'black', backgroundColor: '#DCDCDC', alignSelf: 'flex-end' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CalendarWithNumbers;