import { makeStyles, Theme, createStyles } from '@material-ui/core';

const caseMediaViewerStyle = makeStyles((theme: Theme) =>
  createStyles({
    prevwiedImage: {
      minWidth: 400,
      padding: 16,
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16
    },
    cursor: {
      cursor: 'pointer'
    },
    previewImage: {
      width: '100%'
    },
    nextButton: {
      position: 'absolute',
      right: 25,
      top: '50%',
      backgroundColor: 'white',
      width: 30,
      height: 30,
      borderRadius: 100,
      padding: 0,
      cursor: 'pointer'
    },
    prevButton: {
      position: 'absolute',
      left: 25,
      top: '50%',
      backgroundColor: 'white',
      width: 30,
      height: 30,
      borderRadius: 100,
      padding: 0,
      cursor: 'pointer',
    }
  })
);

export default caseMediaViewerStyle;
