import { makeStyles, Theme, createStyles } from '@material-ui/core';

const calendarStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateNumber: {
            display: 'block',
            fontSize: '0.8em',
            color: 'red',
        },
        paper: {
            position: 'relative',
            padding: 12,
            textAlign: 'left',
            cursor: 'pointer',
            color: theme.palette.text.secondary,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                marginLeft: 0
            },
            border: `1px solid rgba(170, 170, 170, 0.4)`
        },
        alignLeft: {
            textAlign: 'left'
        },
        paperLeft: {
            position: 'relative',
            padding: 12,
            textAlign: 'left',
            cursor: 'pointer',
            color: theme.palette.text.secondary,
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                marginRight: 20
            },
            border: `1px solid rgba(170, 170, 170, 0.4)`
        },
        paperRight: {
            position: 'relative',
            padding: 12,
            textAlign: 'left',
            cursor: 'pointer',
            color: theme.palette.text.secondary,
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                marginLeft: 20
            },
            border: `1px solid rgba(170, 170, 170, 0.4)`
        },

        casesBox: {
            marginTop: 25
        },
        hospitalName: {
            fontSize: 16
        },
        imageBox: {
            margin: '50% auto 0px'
        },
        noWrapWidth: {
            width: 200
        },
        emptyCases: {
            margin: '16px auto 0px',
            textAlign: 'center'
        },
        emptyCasesHint: {
            margon: '0px auto 0px',
            textAlign: 'center'
        },
        mb4: {
            marginBottom: 4
        },
        icon: {
            marginRight: 6,
            fontSize: 22
        },
        avatar: {
            width: 60,
            height: 60,
            padding: '21px 5px',
            borderRadius: '50%',
            backgroundColor: '#F7B500',
            color: '#ffff',
            textAlign: 'center'
        },
        caseCardParent: {
            backgroundColor: '#f8f8f8',
            paddingBottom: 10,
            paddingRight: 10,
            // paddingTop: 10,
        },
        caseCard: {
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            marginLeft: 10,
            border: '1px solid rgba(170, 170, 170, 0.4)',
            boxShadow: '0px 2px 1px 1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            backgroundColor: '#fff',
            borderRadius: '4px',
            padding: 10,
        },
        selectedButton: {
            marginLeft: 'auto'
        },
        checkbox: {
            position: 'absolute',
            top: 10,
            right: 10
        },
        rightDiv: {
            position: 'fixed',
            top: '35%',
            left: 0,
            width: '100%',
            height: '100%'
        },
        patientName: {
            color: '#4aa5ff',
            fontSize: 16,
            fontWeight: 500,
        },
        institution: {
            color: 'black',
            fontSize: 16,
            fontWeight: 500
        },
        procedureName: {
            color: 'gray',
            fontSize: 14,
        },
        headerButton: {
            margin: 10,
            height: 34,
            color: '#ffffff',
            fontWeight: 300,
            [theme.breakpoints.down('lg')]: {
              '&:nth-child(1)': {
                margin: '15px 0px 0px '
              },
              width: '100%',
              fontSize: 14,
              margin: '15px 0px 0px 9px'
            },
            [theme.breakpoints.up('xl')]: {
              margin: 8,
              width: '17%',
              padding: 3
            }
          },
    })
);

export default calendarStyles;
